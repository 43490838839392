<template>
    <div>
        <h3>Liste des membres de staff</h3>
        <fieldset class="fieldset" v-if="userHasOneOfRoles([this.Roles.Admin, this.Roles.StaffMember])">
            <legend>Options</legend>
            <b-checkbox switch v-model="showCapabilities">Voir les rôles</b-checkbox>
            <b-select
              v-show="showCapabilities"
              :options="competitions_types"
              v-model="competition_type"
            >
            </b-select>

            <b-select
              v-show="showCapabilities"
              :options="scopes"
              v-model="scope"
              prepend="Echelon"
            >

            </b-select>
        </fieldset>
        <div>{{staff.length}} membre du staff trouvés répondant aux critères</div>
        <detailed-staff-list
            :items="staff"
            :canAdd = "userHasOneOfRoles([Roles.StaffMember, Roles.Admin])"
            :canEdit = "userHasOneOfRoles([Roles.StaffMember, Roles.Admin])"
            :canRemove = "userHasRole(Roles.Admin)"
            :showCapabilities = "showCapabilities && userHasOneOfRoles([this.Roles.Admin, this.Roles.StaffMember])"
            :showFirstname = "true"
            :showLastname = "true"
            :showRollnumber= "userHasOneOfRoles([this.Roles.Admin, this.Roles.StaffMember])"
            @add="addStaffMember"
            @edit="editStaffMember"
            @remove="showDeletionConfirmationDialog=true"
        >
        </detailed-staff-list>

        <b-modal
          v-model="showDeletionConfirmationDialog"
          @ok="removeStaffMember"
        >
        </b-modal>
    </div>
</template>

<script>
import { REF_COMPETITION_TYPE, REF_SCOPE,  } from "@/LocalDB";
import DetailedStaffList from '@/components/DetailedStaffList';
import ModelViewsHelper from "@/services/store/models/ModelViews";

export default {
    components: { DetailedStaffList, },
    data(){
      return {
        showCapabilities: false,
        competition_type: 'CDF_OUT',
        scope: 'nation',
        showDeletionConfirmationDialog: false,
      }
    },
    computed:{
        staff(){
          if(!this.showCapabilities)
            return ModelViewsHelper.getAllDetailedStaffListItems();
          else
            return ModelViewsHelper.getDetailedStaffListItems(this.competition_type, this.scope, new Date().getFullYear(), null);
        },
        scopes(){
          return REF_SCOPE.all().map(item => { return { value: item.code, text: item.name }; });
        },
        competitions_types(){
          return REF_COMPETITION_TYPE.all().map(item => {return { value: item.code, text: item.name }});
        },
        canViewDetails(){
          return this.userHasOneOfRoles([this.Roles.Admin, this.Roles.StaffMember]);
        },

        /*showCapabilities:{
          get(){ return this.pShowCapabilities; },
          set(val){ this.pShowCapabilities = val;
            this.$showRefresher()
              .then(() => {
                this.refresh();
                this.$$hideRefresher();
              });
          }
        }*/
    },
    methods:{
      /*refresh(){

      },*/
        addStaffMember(){
          this.$showLoader()
            .then(() => {
              this.$router.push({ name: 'Staff'});
            })
        },
        editStaffMember(member){
          this.$showLoader()
            .then(() => {
              this.$router.push({ name: 'Staff', params: { id: member}});
            });
        },
        removeStaffMember(member){
            this.$showRefresher()
              .then(() => {
                console.log("removeStaffMember", member);
                this.$hideRefresher();
              })
        }
    },
    mounted(){
      this.$hideLoader();
    }
}
</script>
